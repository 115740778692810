import Link from "gatsby-link";
import React from "react";
import Button from "../../components/Button";
import Layout from "../../components/layout";
import SondagensBanner from "../../images/SondagensBanner.jpg";
import SEO from "../../components/seo";

function Sondagens() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Sondagens"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${SondagensBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Sondagens
        </h1>
      </section>

      <h2 className="font-bold text-center base-golden-center my-12">
        Nossos serviços
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 m-2 xl:m-12">
        <Link to="/sondagens/st">
          <div className="card text-center">
            <div className="my-8">
              <h3 className="font-bold mb-5 base-golden-center">
                Sondagem à trado (ST)
              </h3>
              <p className="my-8">
                {`É um método de investigação do solo que permite a identificação das
            camadas do subsolo, determinação da profundidade do nível d'água
            (quando existir) e a coleta de amostras de solo.`}
              </p>
              <Button className="mt-5">Saiba mais</Button>
            </div>
          </div>
        </Link>
        <Link to="/sondagens/sptt">
          <div className="card text-center">
            <div className="my-8">
              <h3 className="font-bold mb-5 base-golden-center">
                Sondagem à percussão com torque (SPT-T)
              </h3>
              <p className="my-8">
                A Sondagem à percussão com torque (SPT-T) é realizada com o
                auxílio de um torquimetro para conseguir identificar o atrito
                lateral existente na camada de solo perfurada.
              </p>
              <Button className="mt-3">Saiba mais</Button>
            </div>
          </div>
        </Link>
        <Link to="/sondagens/sptm">
          <div className="card text-center">
            <div className="my-8">
              <h3 className="font-bold mb-5 base-golden-center">
              Sondagem à percussão (SPT)
              </h3>
              <p className="my-8">
                {`A Sondagem SPT Manual é uma técnica de investigação geotécnica do solo. 
                Utilizando um equipamento específico para coleta de amostras e análise em diferentes profundidades. `}
              </p>
              <Button className="mt-5">Saiba mais</Button>
            </div>
          </div>
        </Link>
      </div>
    </Layout>
  );
}

export default Sondagens;
